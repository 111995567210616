import React from 'react'
import { useMenu } from '../context/MenuContext';
import { Link } from "react-router-dom";

const SideBar = () => {
  const {setMenuState} = useMenu();

  return (
    <nav className="navbar navbar-expand-lg bg-light">
      <div className="container-fluid">
        
        <Link to="/" className="nav-link" >
          <h1 style={{color:'white', marginRight:'25px'}}>Art By Jonias</h1>
        </Link>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Colecciones
              </a>
              <ul className="dropdown-menu">

                <Link to="/admin/crear-coleccion" className="nav-link" >
                  <li class="p-3">Crear Colección</li>
                </Link>

                <li><hr className="dropdown-divider"/></li>

                <Link to="/admin/consultar-colecciones" className="nav-link" >
                  <li class="p-3">Consultar Colecciones</li>
                </Link>                
              </ul>
            </li>

             <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Pinturas
              </a>
              <ul className="dropdown-menu">
              <Link to="/admin/crear-pintura" className="nav-link" >
                <li class="p-3">Crear Pintura</li>
              </Link>
                
              <li><hr className="dropdown-divider"/></li>

              <Link to="/admin/consultar-pinturas" className="nav-link" >
                <li class="p-3" >Consultar Pinturas</li> 
              </Link>
                             
              </ul>
            </li>           

          </ul>          
        </div>
      </div>
    </nav>

  )
}

export default SideBar
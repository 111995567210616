import React from 'react';
import { Link } from "react-router-dom";

//IMPORT components
import NavBar from '../components/NavBar';



//import images
import imagen1 from '../../img/12-54.jpeg';
import imagen2 from '../../../src/img/image00008.webp';
import imagen3 from '../../img/12-min-min.jpg';
import imagen4 from '../../img/12-29.jpeg';
import imagen5 from '../../img/makeArt.jpeg';


const UserHome = () => {
 
  
  return (
    <div>
      <NavBar />
      <div className='row m-md-5 m-sm-0 m-0'>

        <div className="col">
          <img src={imagen1} alt="imagen 1" width="100%" />
        </div>

        <div className="col">
          <img src={imagen2} alt="imagen 1" width="100%"/>
        </div>

        <div className="col">
          <img src={imagen3} alt="imagen 1" width="100%"/>
        </div>

      </div>

      <div className="row m-2 m-md-5 justify-content-sm-center">
        <div className="col">
          <h1>About Jonias</h1>
          <p>
            I was born in Cali, Colombia, the land of colors, joy, celebration… and salsa. When I was a little child, I felt a deep connection with art in all its forms, but it was dramatic art and acting, that first captivated me and awakened my creative side as an early adult.
          </p>

          <Link to="/artist" className="nav-link">
            <button className='btn fw-bold'>MY STORY <i class="fa-solid fa-arrow-right"></i></button>
          </Link>
        </div>
      </div>

      <div className="row m-2 m-md-5 align-items-center">

        <div className="col-md-6 col-12">
          <div style={{height:'30rem', backgroundImage:`url(${imagen4})`, backgroundPosition:'center', backgroundSize:'cover'}}></div>
        </div>

        <div className="col-md-5 m-md-5 m-sm mt-3">
          <h1 className='fw-bold text-uppercase'>Visit my art shop</h1>
          
          <Link to="/art" className="nav-link">
            <button className='btn fw-bold'>GO TO SHOP <i class="fa-solid fa-arrow-right"></i></button>
          </Link>
        </div>

      </div>

      <div className="row m-2 m-md-5 align-items-center">        

        <div className="col-md-5 m-md-5 m-sm mt-3">
          <h1 className='fw-bold'>BEHIND THE SCENES</h1>
          <a href="https://www.instagram.com/byjonias/" className="nav-link">
            <i class="fa-brands fa-instagram fs-2"></i>
          </a>
        </div>

        <div className="col-md-6 col-12">
          <div style={{height:'30rem', backgroundImage:`url(${imagen5})`, backgroundPosition:'center', backgroundSize:'cover'}}></div>
        </div>

      </div>

    </div>
  )
}

export default UserHome
//IMPORTS
import { createContext, useContext, useEffect, useState } from "react";

//firebase imports
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore'
import { db } from '../FirebaseConfig';

//INICIALIZACION DEL CONTEXTO
const productoContext = createContext();

export const useProductos = () => { 
    const context = useContext(productoContext);
    return context;
};

export function ProductoContext({ children }) {
    
    //Configuramos los hooks
    const [productos, setProductos] = useState();

    //Referenciamos a la DB firestore
    const Productos = collection(db, "productos");

    //Funcion para mostrar TODOS los docs
    const getProductos = async ()   => {
        const data = await getDocs(Productos);        
        setProductos(
            data.docs.map( (doc) => ( {...doc.data(),id:doc.id}))
        );
    };

    useEffect(() =>{
        getProductos();        
    },[])
     
    return (
        <productoContext.Provider
        value={{ productos }}>
            {children}
        </productoContext.Provider>
    );
}
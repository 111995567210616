// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from '@firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {

    apiKey: "AIzaSyAICfHtHVrSzrsc1weAACZIByieHYx3e_Y",
  
    authDomain: "artbyjonias.firebaseapp.com",
  
    projectId: "artbyjonias",
  
    storageBucket: "artbyjonias.appspot.com",
  
    messagingSenderId: "869406471758",
  
    appId: "1:869406471758:web:475f24bb2fbb57afc5351f"
  
  };
  
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
import React from 'react'
import NavBar from '../components/NavBar';
import { Link } from "react-router-dom";

//import images
import imagen1 from '../../img/Jonias_1.jpeg';


const Artist = () => {
  return (
    <div>
      <NavBar/>

      <div className='row m-1 m-md-5'>
        <div className="col">
        <div style={{height:'60rem', backgroundImage:`url(${imagen1})`, backgroundPosition:'top', backgroundSize:'cover', backgroundAttachment:'fixed'}}></div>
        </div>
      </div>

      <div className="row m-1 m-md-5">
        <div className="col">
          <h4>
          I was born in Cali, Colombia, the land of colors, joy, celebration, and salsa. When I was a little child, I felt a deep connection with art in all its forms, but dramatic art and acting first captivated me and awakened my creative side as an early adult.          </h4>
          <br></br>
          <p>
          After eight years of acting and struggling, I decided to channel my creative energy into fine cuisine to sustain my acting career. Then, I discovered coffee; its flavors, aromas its soul. I learned how to roast it, taste it, prepare it, teach people how to enjoy it, and make coffee in my own life.
          </p>

        </div>
      </div>

      <div className="row m-1 m-md-5">

        <div className="col-md-6 col-12">
          <div style={{height:'100%', backgroundImage:`url(${imagen1})`, backgroundPosition:'center', backgroundSize:'cover'}}></div>
        </div>

        <div className="col-md-5 m-md-5 m-sm">
          <h1 className='fw-bold text-uppercase'>about me</h1>
          <p style={{textAlign:'justify'}}>
          Those small beans kept me connected with my intuition. My contact throughout the coffee sowing, growing, harvesting, roasting, and preparation kept me mindful of our links and made me a performing vehicle behind the bar, one that was now acting differently.
          <br></br><br></br>
          Coffee was my segue to mixology, a world that involves creativity, improvisation, and connection with others. This time behind the bar was about reading people’s emotions while having a plethora of colors, spirits, and mixes at my disposal, where I constructed personalized acts with unique cocktails and conversation.
          <br></br><br></br>
          I found my voice as a bartender and decided to participate in different bartending championships to share my unique worldview, events like Bols Around the World, Havana Grand Prix, and others where I learn from bartenders and baristas from different places and enrich my craft.
          <br></br><br></br>
          This journey around the world made me aware of the need to sacralize arts and share my passion. I led the organization of my city’s group of baristas and bartenders to professionalize our craft and strengthen our network. Moreover, I designed and taught the curricula for new bartenders and baristas currently being used at two colleges. And finally, I became a consultant for different companies in the hospitality business.
          <br></br><br></br>
          As I moved to the United States, the lockdowns during the COVID pandemic posed new challenges for artists, performers, and hospitality businesses. The uncertainties of the future and the impossibility of doing what I love the most led me to drive my creative power toward visual arts, first in photography and now in fine arts.
          <br></br><br></br>
          My works result from eclecticism, influenced by abstract impressionism and expressionism. I aim to portray different sensations triggered by contemporary events without specifying the nature of facts, creating an anchor through which my viewers can relate and explore their emotions.
          <br></br><br></br>
          I subvert the canon of emotions and worldviews with colors and techniques, such as using grays to express happiness or incorporating realist strokes to portray surrealist and unnerving scenes. By inverting the poles of artistic expression, I intend to give salience to the unconscious experience of my creative path while trying to evoke its origins in more abstract ways.
          </p>
          <a href="jonias16@gmail.com" className="nav-link">
            <button className='btn fw-bold'>CONTACT <i class="fa-solid fa-arrow-right"></i></button>
          </a>
        </div>

      </div>

      <div className="row m-1 m-md-5 mt-5 align-items-center">        

        <div className="col-md-5 m-md-5 m-sm">
          <h1 className='fw-bold text-uppercase'>Visit my art shop</h1>
          
          <Link to="/art" className="nav-link">
            <button className='btn fw-bold'>GO TO SHOP <i class="fa-solid fa-arrow-right"></i></button>
          </Link>
        </div>

        <div className="col-md-6">
          <div style={{height:'30rem', backgroundImage:`url(${imagen1})`, backgroundPosition:'center', backgroundSize:'cover'}}></div>
        </div>

      </div>


    </div>
  )
}

export default Artist
import React from 'react';
import { useProductos } from '../../contexts/ProductoContext';

//Import del react router dom
import { Link } from 'react-router-dom';

import SideBar from '../components/SideBar';

//SweetAlert imports
import Swal from 'sweetalert2';
 
//firebase imports
import { deleteDoc, doc } from 'firebase/firestore'
import { db } from '../../FirebaseConfig';

const ConsultarPintura = () => {

  const { productos } = useProductos();  

  //Funcion para eliminar un doc
  const deleteProducto = async (id) => {
    const productoDoc = doc(db, "productos", id)
    await deleteDoc(productoDoc);        
  };

  //Funcion de confirmacion para Sweet Alert 2
  const confirmDelete = (id) => {
    Swal.fire({
    title: '¿Desea eliminar el producto?',
    text: "Esto no se puede revertir!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
    confirmButtonText: '¡Sí, eliminar!'
    }).then((result) => {
      if (result.isConfirmed) { 
          //llamamos a la funcion para eliminar   
          deleteProducto(id)               
          Swal.fire(
          '¡Eliminado!',
          'La pintura fue eliminada.',
          'success'
          )
      }
    })    
  }

  return (    
    <div>      
      <table className="table">        
        <thead>
          <tr>
            <th scope="col">Foto</th>   
            <th scope="col">Nombre</th>
            <th scope="col">Colección</th>
            <th scope="col">Descripción</th>
            <th scope="col">Estado</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        
        <tbody>
        { productos.map( (producto) => (
            <tr key={producto.id}> 
              <th scope="row" width="10%"><img src={producto.foto} className="" alt={producto.nombre} width="100%"/></th>
              <th >{producto.nombre}</th>
              <th >{producto.coleccion}</th>
              <th >{producto.descripcion}</th>
              <th >{producto.estado ? <span class="badge text-bg-success">Activado</span> : <span class="badge text-bg-secondary">Desactivado</span>}</th>
              <th>                
                <Link to={`/editar/producto/${producto.id}`} className="btn btn-primary m-1">
                  <i class="fa-solid fa-pencil"></i>
                </Link>
                <div onClick={() => {confirmDelete(producto.id)}} className="btn btn-danger">
                  <i class="fa-sharp fa-solid fa-trash"></i>
                </div>
              </th>
            </tr>
            ))
          }       
        </tbody>
      </table>
    </div>    
  )
}

export default ConsultarPintura
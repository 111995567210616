import React from 'react';
import { Link } from "react-router-dom";

import imagen from '../../img/logo.png'

const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg bg-light">
        <div className="container-fluid">
            
            <Link to="/" className="nav-link" >
                <img className="navbar-brand" src={imagen} alt='asd' width="200rem"/>
            </Link>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
          

            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                <ul className="navbar-nav ">
                    
                    
                    <li className="nav-item">
                        <Link to="/" className="nav-link" >Home</Link>
                    </li>


                    <li className="nav-item">
                        <Link to="/artist" className="nav-link" >Artist</Link>                        
                    </li>


                    <li className="nav-item">
                        <Link to="/art" className="nav-link" >Art</Link>
                    </li>  


                    <li className="nav-item">
                        <a href="https://opensea.io/collection/joniasrivera-collection" className="nav-link" >NFT´s</a>
                    </li>                    

                </ul>
            </div>
        </div>
    </nav>
  )
}

export default NavBar
//IMPORTS
import { createContext, useContext, useEffect, useState } from "react";

//firebase imports
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../FirebaseConfig';

//INICIALIZACION DEL CONTEXTO
const coleccionContext = createContext();

export const useColecciones = () => { 
    const context = useContext(coleccionContext);
    return context;
};

export function ColeccionContext({ children }) {
    
    //Configuramos los hooks
    const [colecciones, setColecciones] = useState([]);

    //Referenciamos a la DB firestore
    const Colecciones = collection(db, "coleccion");

    //Funcion para mostrar TODOS los docs
    const getColecciones = async ()   => {
        const data = await getDocs(Colecciones);        
        setColecciones(
            data.docs.map( (doc) => ( {...doc.data(),id:doc.id}))
        );
    };
    useEffect(() =>{
        getColecciones();
    },[])
     
    return (
        <coleccionContext.Provider
        value={{ colecciones }}>
            {children}
        </coleccionContext.Provider>
    );
}
//IMPORTS
import { createContext, useContext, useEffect, useState } from "react";

//INICIALIZACION DEL CONTEXTO
const menuContext = createContext();

export const useMenu = () => { 
    const context = useContext(menuContext);
    return context;
};

export function MenuContext({ children }) {
  
    const [menuState, setMenuState] = useState('ConsultarColeccion');
     
    return (
        <menuContext.Provider
        value={{ menuState, setMenuState, useMenu }}>
            {children}
        </menuContext.Provider>
    );
}
import React from 'react';

import SideBar from '../components/SideBar';

//Import context
import { useColecciones } from '../../contexts/ColeccionContext';

const ConsultarColecciones = () => {
  const {colecciones} = useColecciones();

  return (
   <div>
      <SideBar />
      <table className="table">        
        <thead>
          <tr>             
            <th scope="col">Nombre</th>
            <th scope="col">Descripción</th>
            <th scope="col">Estado</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        
        <tbody>
        { colecciones.map( (coleccion) => (
            <tr key={coleccion.id}>               
              <th >{coleccion.nombre}</th>              
              <th >{coleccion.descripcion}</th>
              <th >{coleccion.estado ? <span class="badge text-bg-success">Activada</span> : <span class="badge text-bg-secondary">Desactivada</span>}</th>
              <th>                
                <div className="btn btn-primary">
                  <i class="fa-solid fa-pen-to-square"></i>  Editar
                </div>
              </th>
            </tr>
            ))
          }       
        </tbody>
      </table>
    </div>
  )
}

export default ConsultarColecciones
import './App.css';
import { Routes, Route } from 'react-router-dom';
import AdminHome from './admin/layouts/AdminHome';
import UserHome from './user/layouts/UserHome';
import Login from './admin/layouts/Login';
import Artist from './user/layouts/Artist';
import Product from './user/layouts/Product';
import Shop from './user/layouts/Shop';
import CrearColeccion from './admin/layouts/CrearColeccion';
import ConsultarColecciones from './admin/layouts/ConsultarColecciones';


import {ColeccionContext} from './contexts/ColeccionContext.jsx'
import { ProductoContext } from './contexts/ProductoContext.jsx';
import CrearPintura from './admin/layouts/CrearPintura';
import ConsultarPintura from './admin/layouts/ConsultarPintura';
import ModificarPintura from './admin/layouts/ModificarPintura';

function App() {
  return (
    <ColeccionContext>
    <ProductoContext>

    <div className="App">
      <Routes>

        <Route path='/' exact element={<UserHome />} />
        <Route path='/login' exact element={<Login />} />
        <Route path='/admin' exact element={<AdminHome />} />
        <Route path='/admin' exact element={<AdminHome />} />
        <Route path='/admin/crear-coleccion' exact element={<CrearColeccion />} />
        <Route path='/admin/consultar-colecciones' exact element={<ConsultarColecciones />} />
        <Route path='/admin/crear-pintura' exact element={<CrearPintura />} />
        <Route path='/admin/consultar-pinturas' exact element={<ConsultarPintura />} />
        <Route path='/editar/producto/:id' exact element={<ModificarPintura />} />

        <Route path='/artist' exact element={<Artist />} />
        <Route path='/product' exact element={<Product />} />
        <Route path='/art' exact element={<Shop />} />

      </Routes>
    </div>
    
    </ProductoContext>
    </ColeccionContext>
  );
}

export default App;

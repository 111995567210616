import React from 'react';
import NavBar from '../components/NavBar';

//Import context
import { useColecciones } from '../../contexts/ColeccionContext';
import { useProductos } from '../../contexts/ProductoContext';


const Shop = () => {

  const {colecciones} = useColecciones();
  const { productos } = useProductos();

  return (
    <div>
      <NavBar/>
      { colecciones.map( (coleccion) => (
          <div key={coleccion.id} className='row m-4 p-4 text-start'>
            <div className="row">
              <h1>{coleccion.nombre}</h1>
            </div>
            
            <div className="row">
            { 
              productos.map( (producto) => {                               
                if(producto.coleccion === coleccion.nombre){
                  return ( 
                    producto.foto ?                     
                    <div key={producto.id} className="col-md-2 col-sm-5"> 
                      <img src={producto.foto} className="" alt={producto.nombre} width="100%"/>
                      <h3 className='fw-bold'>{producto.nombre}</h3>               
                       <p>{producto.descripcion}</p> 
                    </div> : null
                  )}                
                })
            }  
            </div>
          </div>
        ))
      } 
    </div>
  )
}

export default Shop


import React, { useState, useEffect } from 'react';

//firebase imports
import { collection, addDoc, getDoc, updateDoc, doc, } from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from '../../FirebaseConfig';

//Import context
import { useColecciones } from '../../contexts/ColeccionContext';
import { useProductos } from '../../contexts/ProductoContext';

//SweetAlert imports
import Swal from 'sweetalert2';

//imports react router dom.
import { useNavigate, useParams } from "react-router-dom";

const ModificarPintura = () => {
  
        const {id} = useParams();

        const {colecciones} = useColecciones();
        const {productos} = useProductos();

        const [ nombre, setNombre ] = useState('');
        const [ precio, setPrecio ] = useState('');
        const [ descripcion, setDescripcion ] = useState('');
        const [ coleccion, setColeccion ] = useState('');
        const [ foto, setFoto ] = useState('');

    
        const update = async (e) => {
            e.preventDefault()
            const productos = doc(db, "productos", id)
            const data = { descripcion: descripcion, nombre: nombre,  foto: foto, precio:precio, coleccion:coleccion};
            await updateDoc(productos, data)
            
            //Envia notificacion al usuario de la correcta modificación en la base de datos.
            await Swal.fire({
                position: 'top-center',
                icon: 'success',
                title: '¡Fue modificado con exito!',
                showConfirmButton: false,
                timer: 1300
            })            
        }

        const getProductoById = async (id) => {
            const productos= await getDoc( doc(db, "productos", id) )
    
            if(productos.exists()) {
                setNombre(productos.data().nombre)    
                setDescripcion(productos.data().descripcion)                
                setFoto(productos.data().foto)
                setPrecio(productos.data().precio)
                setColeccion(productos.data().coleccion)
                
            }else{
                Swal.fire({
                    position: 'top-center',
                    icon: 'error',
                    title: 'El producto no existe.',
                    showConfirmButton: false,
                    timer: 1300
                })
            }
        }


        //Funcion para cargar la foto a firestorage
        const archivoHandler = async (e)=> {

            const archivo = e.target.files[0];
            const storageRef = ref(storage, archivo.name);
  
            // 'file' comes from the Blob or File API
            await uploadBytes(storageRef, archivo)
            .then((snapshot) => {
                console.log('Uploaded a blob or file!');
              
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    setFoto(downloadURL)
                });                         

            })

        }; 

        useEffect( () => {
            getProductoById(id);            
            // eslint-disable-next-line
        }, [])
          

  return (
    
    <form className="m-5 row align-items-center" onSubmit={update}>

        <div className="m-3">
            <label htmlFor="exampleFormControlInput1" className="form-label" >Nombre de la pintura</label>
            <input type="text" className="form-control" id="exampleFormControlInput1" onChange={ (e)=> setNombre(e.target.value)} value={nombre}/>
        </div>

        <div className="col-md-4 col-sm-12">
            <div className="row align-items-center">
                <div className="col">
                    <img src={foto} alt={nombre} width="100%" />
                </div>
                <div className="col">
                    <label htmlFor="formFile" className="form-label" >Añade la foto</label>
                    <input className="form-control" type="file" onChange={archivoHandler} />
                </div>
            </div>
            
        </div>

        <div className="col-md-4 col-sm-12" >
            <label htmlFor="exampleFormControlInput1" className="form-label">Precio</label>
            <input type="text" className="form-control" id="exampleFormControlInput1" onChange={ (e)=> setPrecio(e.target.value)}value={precio}/>
        </div>

        <div className=" col-md-4 col-sm-12">
            <label htmlFor="exampleFormControlInput1" className="form-label">Colección a la que pertenece</label>
            <select class="form-select" aria-label="Default select example" onChange={(e)=>setColeccion(e.target.value)}>
                <option selected>{coleccion}</option>
                { colecciones.map( (coleccion) => (
                    <option key={coleccion.id} value={coleccion.nombre}>{coleccion.nombre}</option>
                ))}                
            </select>
        </div>       

        <div className="m-3">
            <label htmlFor="exampleFormControlTextarea1" className="form-label">Descripción</label>
            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e)=>setDescripcion(e.target.value)}value={descripcion}></textarea>
        </div>

        <div className='col-12'>
            <button className="btn btn-primary" type='submit'>Guardar</button>
        </div> 

    </form>
  )
}

export default ModificarPintura
import React, { useState } from 'react';

//firebase imports
import { collection, addDoc } from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from '../../FirebaseConfig';

//SweetAlert imports
import Swal from 'sweetalert2';


const CrearColeccion = () => {

  const [ nombre, setNombre ] = useState('');
  const [ descripcion, setDescripcion ] = useState('');
  const [ estado, setEstado ] = useState(true);

  //Llamado a la base de datos.
  const coleccion = collection(db, "coleccion");

  //Funcion para crear la coleccion
  const crearColeccion = async (e) => {
    e.preventDefault();

    //Hace la escritura de la base de datos
    await addDoc( coleccion, { descripcion: descripcion, nombre: nombre, estado: estado  } );
    
    //Envia notificacion al usuario de la correcta creacion en la base de datos.
    await Swal.fire({
        position: 'top-center',
        icon: 'success',
        title: '¡Fue creada con exito!',
        showConfirmButton: false,
        timer: 1300
    })             
     
    //Limpia el formulario.
    setNombre("");
    setDescripcion("");      
  };

  return (
    <form className="m-5"  onSubmit={crearColeccion}>
        <div >
            <label for="exampleFormControlInput1" className="form-label">Nombre de la colección</label>
            <input type="text" className="form-control" id="exampleFormControlInput1" onChange={ (e)=> setNombre(e.target.value)} value={nombre}/>
        </div>

        <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">Descripción</label>
            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e)=>setDescripcion(e.target.value)}value={descripcion}></textarea>
        </div>

        <div className='col-12'>
            <button className="btn btn-primary" type='submit'>Guardar</button>
        </div> 

    </form>
  )
}

export default CrearColeccion
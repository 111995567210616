import React from 'react';
import NavBar from '../components/NavBar';

const Product = () => {
  return (
    <div>
      <NavBar/>
      Product
    </div>
  )
}

export default Product
import React, { useState } from 'react';

//firebase imports
import { collection, addDoc } from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from '../../FirebaseConfig';

//Import context
import { useColecciones } from '../../contexts/ColeccionContext';

//SweetAlert imports
import Swal from 'sweetalert2';

const CrearPintura = () => {

        const {colecciones} = useColecciones();

        const [ nombre, setNombre ] = useState('');
        const [ precio, setPrecio ] = useState('');
        const [ descripcion, setDescripcion ] = useState('');
        const [ coleccion, setColeccion ] = useState('');
        const [ estado, setEstado ] = useState(true);
        const [ foto, setFoto ] = useState('');

        //Llamado a la base de datos.
        const productos = collection(db, "productos");


        //Funcion para cargar la foto a firestorage
        const archivoHandler = async (e)=> {

            const archivo = e.target.files[0];
            const storageRef = ref(storage, archivo.name);
  
            // 'file' comes from the Blob or File API
            await uploadBytes(storageRef, archivo)
            .then((snapshot) => {
                console.log('Uploaded a blob or file!');
              
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    setFoto(downloadURL)
                });                         

            })

        }; 


        //Funcion para crear la pintura
        const crearProducto = async (e) => {
            e.preventDefault();

            //Hace la escritura de la base de datos
            await addDoc( productos, { descripcion: descripcion, nombre: nombre, estado: estado, precio:precio,coleccion:coleccion, foto:foto  } );
            
            //Envia notificacion al usuario de la correcta creacion en la base de datos.
            await Swal.fire({
                position: 'top-center',
                icon: 'success',
                title: '¡Fue creada con exito!',
                showConfirmButton: false,
                timer: 1300
            }).then(()=>{
                //Limpia el formulario.
                setNombre("");
                setDescripcion("");
                setColeccion("");
                setPrecio("");
                setFoto("");
            })
        };
    

  return (
    
    <form className="m-5 row" onSubmit={crearProducto}>

        <div className="m-3">
            <label htmlFor="exampleFormControlInput1" className="form-label" >Nombre de la pintura</label>
            <input type="text" className="form-control" id="exampleFormControlInput1" onChange={ (e)=> setNombre(e.target.value)} value={nombre}/>
        </div>

        <div className="col-md-4 col-sm-12">
            <label htmlFor="formFile" className="form-label" >Añade la foto</label>
            <input className="form-control" type="file" onChange={archivoHandler} />
        </div>

        <div className="col-md-4 col-sm-12" >
            <label htmlFor="exampleFormControlInput1" className="form-label">Precio</label>
            <input type="text" className="form-control" id="exampleFormControlInput1" onChange={ (e)=> setPrecio(e.target.value)}value={precio}/>
        </div>

        <div className=" col-md-4 col-sm-12">
            <label htmlFor="exampleFormControlInput1" className="form-label">Colección a la que pertenece</label>
            <select class="form-select" aria-label="Default select example" onChange={(e)=>setColeccion(e.target.value)}>
                { colecciones.map( (coleccion) => (
                    <option key={coleccion.id} value={coleccion.nombre}>{coleccion.nombre}</option>
                ))}                
            </select>
        </div>       

        <div className="m-3">
            <label htmlFor="exampleFormControlTextarea1" className="form-label">Descripción</label>
            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e)=>setDescripcion(e.target.value)}value={descripcion}></textarea>
        </div>

        <div className='col-12'>
            <button className="btn btn-primary" type='submit'>Guardar</button>
        </div> 

    </form>
  )
}

export default CrearPintura
import React from 'react';
import {MenuContext, useMenu} from '../context/MenuContext.jsx';

import SideBar from '../components/SideBar';

import ConsultarPinturas from './ConsultarPintura';
import ConsultarColecciones from './ConsultarColecciones';
import CrearPintura from './CrearPintura';
import CrearColeccion from './CrearColeccion';

const AdminHome = () => {

  const Filtros = () => {
      const {menuState} = useMenu();

      switch (menuState){
        case 'ConsultarPinturas':
            return <ConsultarPinturas />
              
        case 'ConsultarColecciones':
            return <ConsultarColecciones />
                          
        case 'CrearPintura':
            return <CrearPintura />

        case 'CrearColeccion':
            return <CrearColeccion />    
                          
        default: <ConsultarPinturas />
      }
  };

  return (
    <MenuContext>
      <div className="container-fluid">
        <SideBar />      
        <div className="container-fluid">
          <Filtros />
        </div>     
      </div>
    </MenuContext>
  )
}

export default AdminHome